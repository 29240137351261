.products.spec.print {
   .titleStyle--02 {
      font-size: 17px !important;
   }

   .wrapper-spec-tabale h3 {
      font-size: 15px !important;
   }

   .btnNavWrapper {
      .print, .btnReturn {
         display: none !important;
      }
   }   
}
